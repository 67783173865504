@use "sass:math";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

@mixin webpBackground($webp, $fallback) {
  :global(.no-webp) & {
    background-image: url($fallback);
  }

  :global(.webp) & {
    background-image: url($webp);
  }
}

@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@mixin fluid($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    font-size: $min-font-size;
    @media screen and (min-width: $min-vw) {
      font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
    }
    @media screen and (min-width: $max-vw) {
      font-size: $max-font-size;
    }
  }
}

$minWidth: 320px;
$maxWidth: 1440px;
$minTitleFontSize: 34px;
$maxTitleFontSize: 86px;
$minSubTextSize: 15px;
$maxSubTextSize: 20px;
$minButtonTextSize: 14px;
$maxButtonTextSize: 18px;

.pageContentWrapper {
  position: relative;
  z-index: 3;

  @include mqMaxWidth($screenM) {
    margin-left: 58px;
  }
  @include mqMaxWidth($screenS) {
    padding-top: 77px;
    text-align: center;
    width: 100%;
    margin: 0;
  }

  @include mqMaxWidth($screenXs) {
    padding-top: 39px;
  }
}

.pageTitleContainer {

  h1 {
    @include fluid($minWidth, $maxWidth, $minTitleFontSize, $maxTitleFontSize);
    text-transform: capitalize;
    line-height: 100px;
    color: #fff;
    letter-spacing: -0.07em;
    margin: 0;
    @include mqMaxWidth($screenM) {
      line-height: 70px;
    }
    @include mqMaxWidth($screenS) {
      line-height: 55px;
    }
    @include mqMaxWidth($screenXXs) {
      line-height: 48px;
    }
  }

  h3 {
    @include fluid($minWidth, $maxWidth, $minSubTextSize, $maxSubTextSize);
    margin: 0;
    color: $secondaryColor;
    line-height: 23px;
    text-transform: capitalize;
  }
}

.pageNavigationContainer {
  padding-top: 65px;
  position: relative;
  display: inline-block;
  @include mqMaxWidth($screenM) {
    padding-top: 48px;
  }
  @include mqMaxWidth($screenXs) {
    padding-top: 28px;
  }

  .link {
    @include fluid($minWidth, $maxWidth, $minButtonTextSize, $maxButtonTextSize);
    color: #fff;
    line-height: 21px;
    position: relative;
    margin-right: 47px;
    display: inline-block;
    opacity: 0.8;

    @include mqMaxWidth($screenS) {
      &:last-of-type {
        margin-right: 0;
      }
    }

    @include mqMaxWidth($screenXs) {
      margin-right: 27px;
    }

    @include mqMaxWidth($screenS) {
      &:before {
        content: '';
        display: none;
        opacity: 0;
        transition: 0.2s;
      }
    }

    &:after {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &:hover {
      opacity: 1;

      &:before {
        @include mqMaxWidth($screenS) {
          display: inline-block;
          position: absolute;
          width: 28px;
          opacity: 1;
          height: 3px;
          left: 50%;
          transform: translate(-50%);
          bottom: -21px;
          background: #fff;
        }
      }
    }

    &:hover ~ .bottomLine {
      opacity: 1;
    }
  }

  .bottomLine {
    display: inline-block;
    position: absolute;
    opacity: 0;
    transition: 0.2s;
    width: 28px;
    height: 3px;
    transform: translate(-50%);
    bottom: -21px;
    background: #fff;

    @include mqMaxWidth($screenS) {
      display: none;
    }
  }
}

.backgroundImg {
  position: absolute;
  left: 20%;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("assets/NY_background.webp");
  background-position-x: right;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  z-index: 1;

  @include mqMaxWidth($screenM) {
    background-position-x: 62%;
  }

  @include mqMaxWidth($screenS) {
    left: 0;
    background-position-x: 58%;
  }

  @include mqMaxWidth($screenXs) {
    background-position-x: 56%;
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background:
      linear-gradient(
          to bottom,
          rgba(93, 59, 132, 1) 0%,
          rgba(93, 59, 132, 1) 3%,
          rgba(93, 59, 132, 0) 60%
      );
  }
}
